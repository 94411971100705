import { MENU_WIDGET_COMPONENT_IDS, MENU_WIDGET_PRESET_IDS } from 'root/utils/consts';
import { getNavigationBarItems, getNavigationDropdownItems, isValidMenuQueryParam } from './utils';
import { setElementDataAndCollapseIfNeeded } from 'root/utils/setElementDataAndCollapseIfNeeded';
import { NAVIGATION_BAR_ARIA_LABEL, NAVIGATION_BAR_ROLE, MENU_STATES, NAVIGATION_STATES } from './consts';
import type { Preset, PopulatedMenu, ControllerParams, HorizontalMenuItem, NavigationState } from 'root/types';
import type { MenusOrder, MenuState } from './types';
import type { I$W, ILocation, PlatformControllerFlowAPI } from '@wix/yoshi-flow-editor';
import type { PlaceholderImageDisplayValue, ZeroPriceDisplayValue } from './panels/Settings/types';
import type { BiReporter } from 'root/utils/BiReporter';
import { reaction, runInAction } from 'mobx';
import { state } from 'root/state/state';
import type menuModel from './model';
import { observeProps } from 'root/state/observeProps';

type Bind = ControllerParams<typeof menuModel>['$bind'];
type BindAll = ControllerParams<typeof menuModel>['$bindAll'];
export type MenuProps = ControllerParams<typeof menuModel>['$props'];

export class MenuController {
  menus?: PopulatedMenu[];
  menusMap: Record<string, PopulatedMenu> = {};
  menu?: PopulatedMenu;

  constructor(
    private $w: I$W,
    private $bind: Bind,
    private $bindAll: BindAll,
    private $props: MenuProps,
    private isViewer: boolean,
    private t: PlatformControllerFlowAPI['translations']['t'],
    private biReporter: BiReporter,
    private isUseBindExperimentEnabled: boolean,
    private isMenusNavBarNoScrollExperimentEnabled: boolean
  ) {}

  init(
    menuId: string,
    menuQueryParam: string | undefined,
    preset: Preset,
    shouldDisplayCurrency: boolean,
    shouldDisplayVariantCurrency: boolean,
    zeroPriceDisplayOption: ZeroPriceDisplayValue,
    zeroPriceDisplaySpecificSectionIds: string[],
    placeholderImageDisplayValue: PlaceholderImageDisplayValue,
    sectionsWithPlaceholderImageIds: string[],
    placeholderImage: string | undefined
  ) {
    if (this.isUseBindExperimentEnabled) {
      reaction(
        () => this.$props.menusOrder,
        (menusOrder) => {
          const shouldSetMenu = menusOrder && menusOrder[0] !== state.menu?.id;
          shouldSetMenu && this.setMenu(menusOrder[0], menuQueryParam);
          this.setNavigationBarItems(menusOrder);
        }
      );
      observeProps(this.$props, state);
    }
    this.setMenu(menuId, menuQueryParam);

    if (this.isUseBindExperimentEnabled) {
      if (state.menu) {
        this.$bindAll({
          [MENU_WIDGET_COMPONENT_IDS.menuTitle]: {
            text: () => state.menu?.name || '',
          },
          [MENU_WIDGET_COMPONENT_IDS.menuDescription]: {
            text: () => state.menu?.description || '',
            deleted: () => !state.menu?.description,
          },
          [MENU_WIDGET_COMPONENT_IDS.sbsImage]: {
            deleted: () => this.$props.preset !== MENU_WIDGET_PRESET_IDS.sideBySideOneColumn && this.isViewer,
          },
        });
        return true;
      } else {
        return false;
      }
    } else {
      if (this.menu) {
        this.setMenuStateIfNeeded();
        this.setTitle(this.menu);
        this.setDescription(this.menu);
        this.setColumns(
          preset,
          shouldDisplayCurrency,
          shouldDisplayVariantCurrency,
          zeroPriceDisplayOption,
          zeroPriceDisplaySpecificSectionIds,
          placeholderImageDisplayValue,
          sectionsWithPlaceholderImageIds,
          placeholderImage
        );
        this.deleteHiddenImageFromViewer(preset);
        return true;
      } else {
        return false;
      }
    }
  }

  setMenu(menuId: string, menuQueryParam: string | undefined) {
    let menuToSet: PopulatedMenu | undefined;
    if (isValidMenuQueryParam(menuQueryParam)) {
      menuToSet = this.menus?.find(
        (menu: PopulatedMenu) =>
          menu.urlQueryParam === menuQueryParam || menu.urlQueryParam === encodeURI(menuQueryParam)
      );
    } else {
      menuToSet = this.menusMap[menuId];
    }

    if (this.isUseBindExperimentEnabled) {
      runInAction(() => {
        state.menu = menuToSet;
        if (menuToSet) {
          state.menuState = MENU_STATES.menu;
        }
      });
    } else {
      this.menu = menuToSet;
    }
  }

  setMenus(menus: PopulatedMenu[]) {
    this.menus = menus;
    this.menusMap = menus.reduce((acc, menu) => ({ ...acc, [menu.id as string]: menu }), {});
  }

  setMenuStateIfNeeded() {
    const menuState = MENU_STATES.menu;
    const currentState = this.$w(MENU_WIDGET_COMPONENT_IDS.menuMultiStateBox).currentState;
    if (currentState.id !== menuState) {
      this.switchState(menuState);
    }
  }

  setErrorState() {
    if (this.isUseBindExperimentEnabled) {
      this.$bind(MENU_WIDGET_COMPONENT_IDS.errorStateTitle, {
        text: () => this.t('component.menu.error-state'),
      });
      runInAction(() => {
        state.menuState = MENU_STATES.error;
      });
    } else {
      this.switchState(MENU_STATES.error);
      this.$w(MENU_WIDGET_COMPONENT_IDS.errorStateTitle).text = this.t('component.menu.error-state');
    }
  }

  setEmptyState() {
    if (this.isUseBindExperimentEnabled) {
      this.$bind(MENU_WIDGET_COMPONENT_IDS.emptyStateTitle, {
        text: () => this.t('component.menu.empty-state'),
      });
      runInAction(() => {
        state.menuState = MENU_STATES.empty;
      });
    } else {
      this.switchState(MENU_STATES.empty);
      this.$w(MENU_WIDGET_COMPONENT_IDS.emptyStateTitle).text = this.t('component.menu.empty-state');
    }
  }

  switchState(newState: MenuState) {
    const multiStateBox = this.$w(MENU_WIDGET_COMPONENT_IDS.menuMultiStateBox);
    if (this.isUseBindExperimentEnabled) {
      const currentState = multiStateBox.currentState;
      if (currentState.id !== newState) {
        multiStateBox.changeState(newState);
      }
    } else {
      multiStateBox.changeState(newState);
    }
  }

  setTitle(menu: PopulatedMenu) {
    this.$w(MENU_WIDGET_COMPONENT_IDS.menuTitle).text = menu.name;
  }

  setDescription(menu: PopulatedMenu) {
    setElementDataAndCollapseIfNeeded(this.$w, MENU_WIDGET_COMPONENT_IDS.menuDescription, 'text', menu.description);
  }

  setColumns(
    preset: Preset,
    shouldDisplayCurrency: boolean,
    shouldDisplayVariantCurrency: boolean,
    zeroPriceDisplayOption: ZeroPriceDisplayValue,
    zeroPriceDisplaySpecificSectionIds: string[],
    placeholderImageDisplayValue: PlaceholderImageDisplayValue,
    sectionsWithPlaceholderImageIds: string[],
    placeholderImage: string | undefined
  ) {
    const { $w, menu } = this;
    if (!menu) {
      return;
    }
    $w(MENU_WIDGET_COMPONENT_IDS.columns).data = {
      sections: menu.sections,
      preset,
      shouldDisplayCurrency,
      shouldDisplayVariantCurrency,
      zeroPriceDisplayOption,
      zeroPriceDisplaySpecificSectionIds,
      placeholderImageDisplayValue,
      sectionsWithPlaceholderImageIds,
      placeholderImage,
    };
  }

  setNavigationBarItems(menusOrder?: MenusOrder) {
    const shouldShowAllMenusByDefault = !menusOrder;
    runInAction(() => {
      state.navigationBarItems = shouldShowAllMenusByDefault
        ? this.menus?.map((menu) => menu.id as string) || []
        : menusOrder?.filter((id) => !!this.menusMap[id]);
    });

    if (state.navigationBarItems.length === 0) {
      this.setEmptyState();
    }
  }

  setNavigationMenu(menusOrder: MenusOrder | undefined, location: ILocation, flowAPI: PlatformControllerFlowAPI) {
    this.setNavigationBarItems(menusOrder);

    this.$bind(MENU_WIDGET_COMPONENT_IDS.navigationBar, {
      accessibility: { ariaAttributes: { label: () => NAVIGATION_BAR_ARIA_LABEL }, role: () => NAVIGATION_BAR_ROLE },
      deleted: () => state.navigationBarItems?.length === 1,
      menuItems: () =>
        state.menu?.id
          ? getNavigationBarItems(
              state.navigationBarItems,
              this.menusMap,
              state.menu.id,
              this.isMenusNavBarNoScrollExperimentEnabled,
              location
            )
          : [],
      // @ts-expect-error
      onItemClick: ({ item }: { item: HorizontalMenuItem }) => {
        const { id: activeMenuId } = item;
        if (activeMenuId) {
          const activeMenuQueryParam = this.menusMap[activeMenuId].urlQueryParam;
          this.setMenu(activeMenuId, activeMenuQueryParam);
        }

        const currentSitePage = flowAPI.controllerConfig.wixCodeApi.site.currentPage?.name;
        this.biReporter.reportMenusHorizontalNavigationClickBi(
          state.navigationBarItems?.length,
          currentSitePage,
          NAVIGATION_STATES.tabs
        );
      },
    });
  }

  async setNavigationBar(
    preset: Preset,
    shouldDisplayCurrency: boolean,
    shouldDisplayVariantCurrency: boolean,
    zeroPriceDisplayOption: ZeroPriceDisplayValue,
    zeroPriceDisplaySpecificSectionIds: string[],
    placeholderImageDisplayValue: PlaceholderImageDisplayValue,
    sectionsWithPlaceholderImageIds: string[],
    location: ILocation,
    flowAPI: PlatformControllerFlowAPI,
    placeholderImage: string | undefined,
    menusOrder?: MenusOrder,
    navigationState?: NavigationState
  ) {
    const { $w, menusMap, menus } = this;
    const {
      controllerConfig: { wixCodeApi },
      environment,
      experiments,
    } = flowAPI;
    const currentSitePage = flowAPI.controllerConfig.wixCodeApi.site.currentPage?.name;
    const isMultiMenusLayoutExperimentEnabled = experiments.enabled('specs.restaurants.multiMenuLayouts');
    const isMobile = environment.isMobile;
    const shouldShowAllMenusByDefault = !menusOrder;
    const navigationBarItems = shouldShowAllMenusByDefault
      ? menus?.map((menu) => menu.id as string) || []
      : menusOrder.filter((id) => !!menusMap[id]);

    $w(MENU_WIDGET_COMPONENT_IDS.navigationMultiStateBox).changeState(
      isMobile && navigationState?.mobile ? navigationState?.mobile : navigationState?.web
    );

    const navigationLayoutState = $w(MENU_WIDGET_COMPONENT_IDS.navigationMultiStateBox).currentState.id;
    const navigationElement =
      navigationLayoutState === NAVIGATION_STATES.tabs
        ? $w(MENU_WIDGET_COMPONENT_IDS.navigationBar)
        : $w(MENU_WIDGET_COMPONENT_IDS.navigationDropdown);

    navigationElement.accessibility.ariaAttributes.label = NAVIGATION_BAR_ARIA_LABEL;
    navigationElement.accessibility.role = NAVIGATION_BAR_ROLE;

    const setNavigationBarMenuItems = (activeMenuId: string) => {
      const navigationBarMenuItems = getNavigationBarItems(
        navigationBarItems,
        menusMap,
        activeMenuId,
        this.isMenusNavBarNoScrollExperimentEnabled,
        location
      );
      setElementDataAndCollapseIfNeeded(
        $w,
        MENU_WIDGET_COMPONENT_IDS.navigationBar,
        'menuItems',
        navigationBarMenuItems
      );
    };

    const setNavigationDropdownOptions = (activeMenuId: string) => {
      const navigationDropdownOptions = getNavigationDropdownItems(navigationBarItems, menusMap);
      const activeMenuIndexInDropdown = navigationDropdownOptions.findIndex((option) => option.value === activeMenuId);

      setElementDataAndCollapseIfNeeded(
        $w,
        MENU_WIDGET_COMPONENT_IDS.navigationDropdown,
        'options',
        navigationDropdownOptions
      );

      $w(MENU_WIDGET_COMPONENT_IDS.navigationDropdown).selectedIndex = activeMenuIndexInDropdown;
    };

    // Hotfix for navigation bar in vertical menu does not exist - should investigate
    try {
      if (navigationBarItems.length > 1) {
        if (isMultiMenusLayoutExperimentEnabled && navigationLayoutState === NAVIGATION_STATES.dropdown) {
          setNavigationDropdownOptions(this.menu?.id as string);
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          navigationElement.onChange((event: any) => {
            const selectedMenuId = event.target.value;
            const isFirstMenu = navigationBarItems[0] === selectedMenuId;
            const activeMenuQueryParam = menusMap[selectedMenuId].urlQueryParam;

            if (isValidMenuQueryParam(activeMenuQueryParam)) {
              if (isFirstMenu) {
                wixCodeApi.location.queryParams.remove(['menu']);
              } else {
                wixCodeApi.location.queryParams.add({ menu: activeMenuQueryParam });
              }
            } else {
              wixCodeApi.location.queryParams.add({ menuId: selectedMenuId });
            }

            this.biReporter.reportMenusHorizontalNavigationClickBi(
              navigationBarItems.length,
              currentSitePage,
              NAVIGATION_STATES.dropdown
            );

            this.init(
              selectedMenuId || navigationBarItems[0],
              activeMenuQueryParam,
              preset,
              shouldDisplayCurrency,
              shouldDisplayVariantCurrency,
              zeroPriceDisplayOption,
              zeroPriceDisplaySpecificSectionIds,
              placeholderImageDisplayValue,
              sectionsWithPlaceholderImageIds,
              placeholderImage
            );
          });
        } else {
          setNavigationBarMenuItems(this.menu?.id as string);

          navigationElement.onItemClick(({ item }: { item: { link: string; id: string } }) => {
            const { id } = item;
            const activeMenuId = id;
            const activeMenuQueryParam = menusMap[id].urlQueryParam;

            setNavigationBarMenuItems(activeMenuId);

            this.biReporter.reportMenusHorizontalNavigationClickBi(
              navigationBarItems.length,
              currentSitePage,
              NAVIGATION_STATES.tabs
            );

            this.init(
              activeMenuId || navigationBarItems[0],
              activeMenuQueryParam,
              preset,
              shouldDisplayCurrency,
              shouldDisplayVariantCurrency,
              zeroPriceDisplayOption,
              zeroPriceDisplaySpecificSectionIds,
              placeholderImageDisplayValue,
              sectionsWithPlaceholderImageIds,
              placeholderImage
            );
          });
        }
      } else if (navigationBarItems.length === 1) {
        navigationElement.delete();
      } else {
        this.setEmptyState();
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
  }

  deleteHiddenImageFromViewer(preset: Preset) {
    const shouldDeleteImage = preset !== MENU_WIDGET_PRESET_IDS.sideBySideOneColumn && this.isViewer;
    if (shouldDeleteImage) {
      this.$w(MENU_WIDGET_COMPONENT_IDS.sbsImage).delete();
    }
  }
}
